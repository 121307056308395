import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/Projects',
    name: 'Projects',
    component: () => import('../views/Projects/Projects.vue')
  },
  {
    path: '/Pacifake',
    name: 'Pacifake',
    component: () => import('../views/Projects/Project_Pacifake.vue')
  },
  // 404
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
