import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import Vue from 'vue'

import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { getLanguage, setLanguage } from '@/utils/utils'


// Create App Vue
const app = createApp(App)

// Add getLanguage & setLanguage to Vue
setLanguage("fr");
const language = getLanguage()
app.provide('language', language)
app.config.globalProperties.$language = language

library.add(fas)
library.add(far)
app.component('font-awesome-icon', FontAwesomeIcon)

app.use(router)
app.mount('#app')
