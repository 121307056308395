
// eslint-disable-next-line
/* eslint-disable */

import { defineComponent } from 'vue'

import Background from '@/components/Background.vue'

export default defineComponent({
    name: 'App',
    components: {
        Background,
    },
    created() {
        var scripts = [
            "https://kit.fontawesome.com/35794199b8.js"
        ];

        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        const countapi = require('countapi-js');
        countapi.hit('www.vincentvaucouleur.fr', 'visits').then((result: any) => {
            console.log("FR:" + result.value);
        });

        // countapi.hit('www.vincentvaucouleur.com', 'visits').then((result: any) => {
        //     console.log("COM:" + result.value);
        // });

    },
    methods: {
    },
    mounted() {
        window.scrollTo(0, 0);
    }
})
