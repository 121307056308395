/* ************************************************************************** */
/*                                                                            */
/*                                                        :::      ::::::::   */
/*   utils.ts                                           :+:      :+:    :+:   */
/*                                                    +:+ +:+         +:+     */
/*   By: vvaucoul <vvaucoul@student.42.Fr>          +#+  +:+       +#+        */
/*                                                +#+#+#+#+#+   +#+           */
/*   Created: 2022/11/14 11:24:43 by vvaucoul          #+#    #+#             */
/*   Updated: 2022/11/14 11:24:43 by vvaucoul         ###   ########.fr       */
/*                                                                            */
/* ************************************************************************** */

let language = localStorage.getItem('language') || 'en' as string;

export function getLanguage() {
    return language;
}

export function setLanguage(lang: string) {
    language = lang;
    localStorage.setItem('language', lang);
}
